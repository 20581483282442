<template>
  <PortalCard :id="componentId">
    <template #default>
      <div class="empty-text-section">
        <div
          v-if="!!slots.icon"
          class="empty-icon"
        >
          <slot name="icon" />
        </div>

        <h3 class="empty-title">
          {{ title }}
        </h3>
        <p
          v-if="description"
          class="empty-description"
        >
          {{ description }}
        </p>

        <slot name="action" />
      </div>

      <slot name="default" />
    </template>
  </PortalCard>
</template>

<script setup lang="ts">
import type { EmptyStateProps } from '#imports'

const {
  title,
  description = '',
  styles = '',
} = defineProps<EmptyStateProps>()

const slots = defineSlots<{
  default?: () => any
  icon?: () => any
  action?: () => any
}>()

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)
</script>

<style lang="scss" scoped>
.empty {
  &-icon {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  }

  &-text-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--kui-space-50, $kui-space-50);
    padding: var(--kui-space-130, $kui-space-130) 0;
  }

  &-title {
    font-size: var(--kui-font-size-50, $kui-font-size-50);
    font-weight: var(--kui-font-weight-bold, $kui-font-weight-bold);
    line-height: var(--kui-line-height-40, $kui-line-height-40);
    margin: var(--kui-space-0, $kui-space-0);
  }

  &-description {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    margin-bottom: var(--kui-space-70, $kui-space-70);
    text-align: center;
  }
}
</style>
